import React , { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import Header from './components/global/Header';
import Menu from './components/global/Menu';
import Footer from './components/global/Footer';
import PublicRoutes from './routes/PublicRoutes';
import AdminRoutes from './routes/AdminRoutes';
import { BrowserRouter as Router } from "react-router-dom";

import axios from 'axios';

interface SiteInfo {
  siteTitle: string;
  title: string;
  description: string;
  favicon: string;
  logo: string;
  address: string;
  email: string;
  emailShortTest: string;
  phone: string;
  phoneShortTest: string;
  support_phone_no: string;
  supportPhoneShortTest: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  pinterest: string;
  youtube: string;
}

function App() {  
  const userLogin = false;  
  const linkUrl = window.location.pathname;
  const [siteInfo, setSiteInfo] = useState<SiteInfo | null>(null);
  useEffect(() => {
    // Fetching the site info data from public/json-data/siteInfo.json
    axios.get("https://api.adi-bd.org/api/site-info/1")
        .then((response) => {
            setSiteInfo(response.data.data);
        })
        .catch((error) => {
            console.error("Error fetching the site info:", error);
        });
  }, []);    

  return (
    <>
      <Router>
        {/* {userRole === 'admin' && <Header projectName={projectName} />} */}
        {userLogin ? (
        <div>
          <AdminRoutes />
        </div>
      ) : (
        <div>
          <Header siteInfo={siteInfo} />
          <Menu linkUrl={linkUrl} />
          <PublicRoutes />
          <Footer siteInfo={siteInfo}/>
        </div>
      )}
      </Router>
    </>
  );
}

export default App;
