import React  from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faMobile, faEnvelope, faLocationArrow } from "@fortawesome/free-solid-svg-icons";

interface HeaderProps {
    siteInfo: SiteInfo | null
}
interface SiteInfo {
    siteTitle: string
    title: string
    description: string
    favicon: string
    logo: string
    address: string
    email: string
    emailShortTest: string
    phone: string
    phoneShortTest: string
    support_phone_no: string
    supportPhoneShortTest: string
    facebook: string
    twitter: string
    linkedin: string
    instagram: string
    pinterest: string
    youtube: string
}

const Footer: React.FC<HeaderProps> = ({siteInfo}) => { 
    return (
        <>
        <div className="footer">
            <div className="py-0">
                <div className="footer-top container text-white">
                    <div className="row py-5">
                        <div className="col-md-4 col-lg-4 col-sm-12">
                            {/* <h4 className="text-white">{siteInfo?.siteTitle} </h4> */}
                            <h4 className="text-white mb-4">Alternative Development Initiatives (ADI)</h4>
                            <p>
                                <FontAwesomeIcon icon={faLocationArrow} />
                                {siteInfo?.address}
                            </p>
                            <p><FontAwesomeIcon icon={faMobile} /> {siteInfo?.phone}</p>
                            <p><FontAwesomeIcon icon={faEnvelope} /> {siteInfo?.email}</p>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-12">
                            <h4 className="text-white">Pages</h4>
                            <li><a href="#0">About Us</a></li>
                            <li><a href="#0">Programs</a></li>
                            <li><a href="#0">Publications</a></li>
                            <li><a href="#0">Governance</a></li>
                        </div>
                        <div className="col-md-2 col-lg-2 col-sm-12">
                            <h4 className="text-white">Important Links</h4>
                            <li><a href="#0">Privacy Policy</a></li>
                            <li><a href="#0">Career</a></li>
                            <li><a href="#0">Terms & Conditions</a></li>                          
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-12">
                            <h4 className="text-white">Don't Miss out</h4>
                            <p>Don’t miss our future updates! Get Subscribed Today!</p>
                            <form className="inline d-flex">
                                <input type="search" className="form-control radius" placeholder="Subscribe Now !"/> &nbsp; 
                                <button className="btn adi-btn-secondary"><FontAwesomeIcon icon={faPaperPlane} /></button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="container copyright text-center text-white py-2">
                    Copyright &copy; 2024 | All right reserved.
                </div>
            </div>
        </div>
        </>
    );
};

export default Footer;