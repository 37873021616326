import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
import SliderHome from "../../components/PageComponents/SliderHome";

const HomePage = () => {    
    return (
        <>
            <div className=" py-2">
                <div className="container">
                <img src="image/joint.png" alt="" className="img img-responsive w-100"/>
                </div>
                <SliderHome/>
            </div>
            <div className="row m-0 p-0">      
                <div className="container">
                    <section className="row about-section">
                        <div className="col-7 mx-auto">
                            <div className="row py-5">
                                <div className="col-12">
                                    <h3 className="display-3 my-3 adi-text-primary">About Us (ADI)</h3>
                                    <div className="strong fw-bold">---</div> 
                                    <div className="p my-3">
                                        Alternative Development Initiative (ADI) is a non-profit development organization that began in 1993 with the sole interest and development efforts of a few like-minded people with extensive expertise in the country’s development sectors. The primary goal of ADI is to involve the rural poor in development activities that will help them gain capacity and develop their socioeconomic and moral values. The organization believes in the integrated development method, which includes participatory management and activity implementation based on people’s perceived needs.
                                        <br/><br/>
                                        Since its inception in April 1993, the organization has long been a target of rural poverty-stroke households, assisting them financially as well as engaging them in development activities to change their capacity in an effort to bring positive change in their economic and social state. We grow together through active participatory management and an active need-based plan.
                                    </div>
                                    <div className="p my-4">
                                        <a href="#0" className="btn adi-btn-secondary">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="row">
                        <div className="col-7 mx-auto">
                            <div className="row py-5">
                                <div className="col-12">
                                    <p className="fw-bold">Alternative Development Initiative (ADI)</p>
                                    <h3 className="display-2 my-3 adi-text-primary">Goals & Objectives</h3>
                                    <div className="strong fw-bold">---</div> 
                                    <div className="paragraph my-3">
                                        Bangladesh is one of the world’s most densely populated developing countries. The vast majority of people in this country live in villages. The majority of these rural people live in abject poverty. The organization’s main goals are to alleviate their poverty by involving them in various development projects and to provide them with monetary and technical assistance collectively in order to improve their standard of living.
                                        <br/><br/>
                                        
                                        The overall goal of ADI is to bring livelihood security to poorer households by involving them in development activities. The specific objectives of ADI are:
                                        <br/><br/>
                                        <ul>
                                            <li>To develop rural women’s institutions for restoring and protecting their interest that would help establish their rights and realize their role in the society.</li>
                                            <li>To help rural communities to improve their capacity to avail local resources and available services.</li>
                                            <li>help rural poor communities to develop entrepreneurs.</li>
                                            <li>o generate community awareness about environmental degradation and assist them to conservation of natural resources.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="row">
                        <div className="col-8 mx-auto py-5">
                            <div className="section-header text-center">
                                <h3 className="display-3 adi-text-primary">Micro - FINANCE</h3>
                                <span>&mdash;</span>
                            </div>
                            <div className="row p-5">
                                <div className="col-md-8 col-lg-8col-sm-12">
                                    <a href="#0">
                                    <div className="card text-center">
                                        <div className="card-body">
                                            <img src="image/adi-fisheries.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <h4 className="card-title pt-2">
                                                ADI Fisheries
                                            </h4>
                                            <div className="">
                                                As an Agricultural country Bangladesh has enormous...
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12">
                                    <a href="#0">
                                    <div className="card text-center h-100">
                                        <div className="card-body">
                                            <img src="image/agriculture.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <h4 className="card-title pt-2">
                                                Agricultural
                                            </h4>
                                            <div className="">
                                                As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology knowledge can’t lead this country to its potential growth.
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 py-4">
                                    <a href="#0">
                                    <div className="card text-center h-100">
                                        <div className="card-body">
                                            <img src="image/education.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <h4 className="card-title pt-2">
                                                Education Program
                                            </h4>
                                            <div className="">
                                                Education Program : Scholarship program for ultra-poor
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 py-4">
                                    <a href="#0">
                                    <div className="card text-center h-100">
                                        <div className="card-body">
                                            <img src="image/microcredit.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <h4 className="card-title pt-2">
                                                Buniad Ultra Poor Loans
                                            </h4>
                                            <div className="">
                                                Buniad This loan is provided to the people who are.
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 py-4">
                                    <a href="#0">
                                    <div className="card text-center h-100">
                                        <div className="card-body">
                                            <img src="image/jagoron.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <h4 className="card-title pt-2">
                                                Jagoron Loans
                                            </h4>
                                            <div className="">
                                                Over the past two twenty years ADI has been providing
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="row">
                        <div className="col-8 mx-auto py-5">
                            <div className="section-header text-center">
                                <h2 className="adi-text-primary display-3">ADI - Activities</h2>
                                <p className="lead">The overall goal of ADI is to bring livelihood security to poorer households <br/>by involving them in development ctivities.</p>
                            </div>
                            <div className="row p-5">
                                <div className="col-md-4 col-lg-4 col-sm-12 my-2 ">
                                    <a href="#0">
                                    <div className="card text-center h-100">
                                        <div className="card-body">
                                            <img src="image/adi-fisheries.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <h4 className="card-title pt-2">
                                                ADI Fisheries
                                            </h4>
                                            <div className="">
                                                As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 my-2 ">
                                    <a href="#0">
                                    <div className="card text-center h-100">
                                        <div className="card-body">
                                            <img src="image/agriculture.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <h4 className="card-title pt-2">
                                                Agricultural
                                            </h4>
                                            <div className="">
                                                As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 my-2 ">
                                    <a href="#0">
                                    <div className="card text-center h-100">
                                        <div className="card-body">
                                            <img src="image/education.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <h4 className="card-title pt-2">
                                                Education Program
                                            </h4>
                                            <div className="">
                                                As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 my-3">
                                    <a href="#0">
                                    <div className="card text-center h-100">
                                        <div className="card-body">
                                            <img src="image/microcredit.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <h4 className="card-title pt-2">
                                                Buniad Ultra Poor Loans
                                            </h4>
                                            <div className="">
                                                As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 my-3">
                                    <a href="#0">
                                    <div className="card text-center h-100">
                                        <div className="card-body">
                                            <img src="image/jagoron.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <h4 className="card-title pt-2">
                                                Jagoron Loans
                                            </h4>
                                            <div className="">
                                                As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 my-3">
                                    <a href="#0">
                                    <div className="card text-center h-100">
                                        <div className="card-body">
                                            <img src="image/jagoron.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <h4 className="card-title pt-2">
                                                Jagoron Loans
                                            </h4>
                                            <div className="">
                                                As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="row">
                        <div className="col-8 mx-auto py-5">
                            <div className="section-header text-center">
                                <h2 className="display-3 adi-text-primary">ADI - News</h2>
                                <p className="lead">Bangladesh is one of the world's most densely<br/>
                                populated developing countries..</p>
                            </div>
                            <div className="row p-5">
                                <div className="col-md-4 col-lg-4 col-sm-12 my-2 ">
                                    <a href="#0">
                                    <div className="card text-center">
                                        <div className="card-body">
                                            <img src="image/adi-fisheries.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <div className="fw-bold pt-2">
                                                As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 my-2 ">
                                    <a href="#0">
                                    <div className="card text-center">
                                        <div className="card-body">
                                            <img src="image/agriculture.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <div className="fw-bold pt-2">
                                                As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 my-2 ">
                                    <a href="#0">
                                    <div className="card text-center">
                                        <div className="card-body">
                                            <img src="image/education.webp" alt="" className="img img-responsive w-100 rounded"/>
                                            <div className="fw-bold pt-2">
                                                As an Agricultural country Bangladesh has enormous agricultural assets one of them is fisheries but lack of technology... 
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </div>                                
                            </div>
                            <div className="text-center">
                                <a href="#0" className="btn adi-btn-secondary">View All</a>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default HomePage;