import React, { useEffect, useState, lazy, Suspense } from 'react'
import { useParams} from 'react-router-dom';
import axios from 'axios';
import AboutPage from './AboutPage';
import AnnualReort from './AnnualReport';
import AnnualReport from './AnnualReport';
interface Data {
    id: number;
    name: string;
    title: string;
    subtitle: string | null;
    description: string | null;
    thumb: string | null;
    banner_img: string | null;
    page_template: string | null;
    slug: string;
    tags: string[] | null;
    meta_key: string | null;
    meta_description: string | null;
    status: number;
    created_at: string | null;
    updated_at: string | null;
    sections: any[]; // or define a specific interface for sections if you know the structure
  }

  interface Response {
    data: Data;
    status: number;
  }


export default function DefaultPage() {
const { slug } = useParams();
const [page, setPage] = useState<Data | null>(null);
const [loading, setLoading] = useState(true);
const DefaultPage = () => <div>No template specified</div>;

// Lazy-load page templates
const pageTemplates = {
  'about-page': lazy(() => import('./AboutPage')),
  'blog-page': lazy(() => import('./BlogPage')),
  // Add more templates here
};
const fetchPageData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://api.adi-bd.org/api/page/' + slug);
      if(response.status === 200){
        setPage(response.data.data);
        console.log('__data__', page);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchPageData();
  }, [slug]);

//   const pageTemplates = {
//     'about-page': lazy(() => import('./AboutPage')),
//     'blog-page': lazy(() => import('./BlogPage')),
//     // add more templates here
//   };
//   const TemplateComponent = pageTemplates[page?.page_template as keyof typeof pageTemplates];

// if (TemplateComponent !== undefined) {
//   return <TemplateComponent />;
// } else {
//   return (
//       console.log('page?.page_template', page?.page_template),
//     <div>Default template: {page?.page_template}
    
//     <div>{page?.name}</div>
//         <div>{page?.title}</div>
//         <div>{page?.subtitle}</div>
//         <div>{page?.description}</div>
//         {page?.sections?.map((section, index) => (
//             <div key={index}>
//                 <h2>{section.title}</h2>
//                 <p>{section.content}</p>
//                 <div>
//                     {section.widgets?.map((widget: any, widgetIndex: number) => (
//                         <div key={widgetIndex}>
//                         <h3>{widget.title}</h3>
//                         <p>{widget.description}</p>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         ))}
//     </div>
//   )
  
// }

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   const template = page?.page_template;

  
//   const TemplateComponent =
//     template && pageTemplates[template as keyof typeof pageTemplates]
//       ? pageTemplates[template as keyof typeof pageTemplates]
//       : null;  

  return (
    <>
        <div>
        {loading ? (
          <div  style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only adi-text-primary">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <>

          {page?.sections[0]?.title === 'About Page' ? (
              // Render the AboutPage component for the first matching "About Page" title
              <AboutPage pageData={page} />
          ) : (
              page?.page_template === 'AnnualReport' ? (
                // Render the AnnualReport component for the "Annualreport" page_template
                <AnnualReport pageData={page} />
              ) : (
                // Render the default structure for all other unmatched sections
                <>
                  <div className=''>
                    <div className='about-page-area' style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div className='text-center'>
                        <h3 className='adi-text-primary py-3'>{page?.name}</h3>
                        <div>{page?.subtitle}</div>
                        <div>{page?.description}</div>
                        <div>{page?.page_template}</div>
                        <div className='container'>
                          {page?.thumb !== null && page?.thumb !== undefined ? (
                            <img src={'https://file.adi-bd.org/' + page?.thumb} alt="n/a" className='img-fluid my-3' />
                          ) : (
                            <div></div>
                          )}
                        </div>
                        {page?.sections?.map((section, index) => (
                          <div key={index}>
                            <h2>{section.title}</h2>
                            <p>{section.content}</p>
                            <div>
                              {section.widgets?.map((widget: any, widgetIndex: number) => (
                                <div key={widgetIndex}>
                                  <h3>{widget.title}</h3>
                                  <p>{widget.description}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )
          )}
      </>
    )}
            {/* <h1>DefaultPage</h1>  */}
            {/* <div dangerouslySetInnerHTML={{ __html: page[0]?.description || ''}} ></div> */}
            {/* <div>{page?.name}</div>
            <div>{page?.title}</div>
            <div>{page?.subtitle}</div>
            <div>{page?.description}</div>
            <div>{page?.page_template}</div> */}
           


            {/* <div>{page[0]?.title}</div> */}
            {/* <Suspense fallback={<div>Loading template...</div>}>
                {template && TemplateComponent ? (
                    <TemplateComponent />
                ) : (
                    // <DefaultPage />
                    <div>No template found</div>
                )}
            </Suspense> */}
        </div>
    </>
  )
}
