import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMobile, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

// import axios from "axios";

interface HeaderProps {
    siteInfo: SiteInfo | null;
}
interface SiteInfo {
    siteTitle: string;
    title: string;
    description: string;
    favicon: string;
    logo: string;
    address: string;
    email: string;
    emailShortTest: string;
    phone: string;
    phoneShortTest: string;
    support_phone_no: string;
    supportPhoneShortTest: string;
    facebook: string;
    twitter: string;
    linkedin: string;
    instagram: string;
    pinterest: string;
    youtube: string;
}


const Header: React.FC<HeaderProps> = ({siteInfo}) => {  
    return (
        <>
            <div className="container header">
                <div className="topbar">
                    <div className="row">
                        <div className="col-xl-6 col-lg-3 col-sm-3 col-3">
                            <div className="icon">
                                <Link to="/">
                                    <img src="https://file.adi-bd.org/logo.png" alt="n/a" className="img-fluid"/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-3 col-3">
                            <div className="icon topbar-grid d-lg-flex d-block align-items-center">
                                <div className="font-icon">
                                    <FontAwesomeIcon icon={faPhone}/>
                                </div>
                                <div className="d-md-block d-none">
                                    <Link to="#0" target="_blank">{siteInfo?.phone}</Link>
                                    {/* <p>{siteInfo?.phoneShortTest}</p>                                     */}
                                    <p>ADI Hotline</p>                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-3 col-3">
                            <div className="icon topbar-grid d-lg-flex d-block align-items-center">
                                <div className="font-icon">
                                    <FontAwesomeIcon icon={faMobile}/>
                                </div>
                                <div className="d-md-block d-none">                                
                                    <Link to="#0" target="_blank">{siteInfo?.support_phone_no}</Link>
                                    {/* <p className="small">{siteInfo?.supportPhoneShortTest}</p> */}
                                    <p className="small">24/7 Client Support</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-3 col-3">
                            <div className="icon topbar-grid d-lg-flex d-block align-items-center">
                                <div className="font-icon">
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                </div>
                                <div className="d-md-block d-none">
                                    <Link to="#0" target="_blank">{siteInfo?.email}</Link>
                                    <p>{siteInfo?.emailShortTest}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;