import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { faBars, faTimes, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
interface MenuProps {
    linkUrl: string
}
interface ManuItem {
    [x: string]: any;
    id: number;
    parent_id: number | null;
    title: string;
    subtitle: string | null;
    location: string;
    slug: string;
    status: number;
    created_at: string;
    updated_at: string;
  }
const Menu: React.FC<MenuProps> = ({ linkUrl }) => {
    // usestate for toogle menu
    const [showMenu, setShowMenu] = React.useState(false);
    // function to change link url data 
    const changeLinkUrl = (url: string) => {
        setShowMenu(false);
        if (url === '/') {
            return;
        }
        // window.location.href = url;
    };

    const [menu, setMenu] = useState<ManuItem | null>(null);

    const fetchMenuData = async () => {
        try {
            const response = await axios.get('https://api.adi-bd.org/api/menus');
            if(response.status === 200){
            setMenu(response.data.data);
            console.log('__data__', menu);
            }
        } catch (error) {
            console.error(error);
        }
        };
        useEffect(() => {
        fetchMenuData();
        }, [0]);
    return (
        <>
            <div className="main-menu">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-10">
                            <ul className={`menu ${showMenu ? 'active' : ''}`} >
                                <li className="d-block d-lg-none text-end text-white py-1" onClick={() => changeLinkUrl('/')}> <FontAwesomeIcon icon={faTimes} /></li>
                                {/* Regular Menu Items */}
                                <li className="menu-item"><Link to="/" onClick={() => changeLinkUrl('/')} className={`${linkUrl === '/' ? 'active' : ''}`}>Home</Link></li>
                                {/* Mega Menu Item */}
                                <li className="menu-item mega-menu">
                                    <Link to="/about" onClick={() => changeLinkUrl('/about')} className={`${linkUrl === '/about' ? 'active' : ''}`}>About <FontAwesomeIcon icon={faChevronDown} /></Link>
                                    <div className="mega-menu-content">
                                        <div className="mega-menu-row">
                                            <div className="mega-menu-column border-0">
                                                <h3 className="mx-3 adi-text-primary">ABOUT US</h3>
                                                {menu &&
                                                    menu.map((item: any) =>
                                                    item.location === 'ab1' ? ( // Check if the location is 'ab1'
                                                        <ul className="m-0 p-0">
                                                            <li key={item.id}>
                                                            <Link to={`/page/${item.slug}`}>{item.title}</Link>
                                                            </li>
                                                        </ul>
                                                    ) : null // Skip rendering if the location is not 'ab1'
                                                )}
                                            </div>
                                            <div className="mega-menu-column border-0">
                                                <h3 className="mx-3 adi-text-primary">Governance</h3>
                                                {menu &&
                                                    menu.map((item: any) =>
                                                    item.location === 'ab2' ? ( // Check if the location is 'ab1'
                                                        <ul className="m-0 p-0">
                                                            <li key={item.id}>
                                                            <Link to={`/page/${item.slug}`}>{item.title}</Link>
                                                            </li>
                                                        </ul>
                                                    ) : null // Skip rendering if the location is not 'ab1'
                                                )}
                                            </div>
                                            <div className="mega-menu-column border-0">
                                                <h3 className="mx-3 adi-text-primary">Working Area & Partners</h3>
                                                {menu &&
                                                    menu.map((item: any) =>
                                                    item.location === 'ab3' ? ( // Check if the location is 'ab1'
                                                        <ul className="m-0 p-0">
                                                            <li key={item.id}>
                                                            <Link to={`/page/${item.slug}`}>{item.title}</Link>
                                                            </li>
                                                        </ul>
                                                    ) : null // Skip rendering if the location is not 'ab1'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="menu-item mega-menu">
                                    <Link to="/program" onClick={() => changeLinkUrl('/program')} className={`${linkUrl === '/program' ? 'active' : ''}`}>Program & Services <FontAwesomeIcon icon={faChevronDown} /></Link>
                                    <div className="mega-menu-content">
                                        <div className="mega-menu-row">
                                            <div className="mega-menu-column border-0">
                                                <h3 className="mx-3 adi-text-primary">Microfinance Loan Products</h3>
                                                {menu &&
                                                    menu.map((item: any) =>
                                                    item.location === 'ps1' ? ( // Check if the location is 'ab1'
                                                        <ul className="m-0 p-0">
                                                            <li key={item.id}>
                                                            <Link to={`/page/${item.slug}`}>{item.title}</Link>
                                                            </li>
                                                        </ul>
                                                    ) : null // Skip rendering if the location is not 'ab1'
                                                )}
                                            </div>
                                            <div className="mega-menu-column border-0">
                                                <h3 className="mx-3 adi-text-primary">Savings Products</h3>
                                                {menu &&
                                                    menu.map((item: any) =>
                                                    item.location === 'ps2' ? ( // Check if the location is 'ab1'
                                                        <ul className="m-0 p-0">
                                                            <li key={item.id}>
                                                            <Link to={`/page/${item.slug}`}>{item.title}</Link>
                                                            </li>
                                                        </ul>
                                                    ) : null // Skip rendering if the location is not 'ab1'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="menu-item mega-menu">
                                    <Link to="/publication" onClick={() => changeLinkUrl('/publication')} className={`${linkUrl === '/publication' ? 'active' : ''}`}>Development Projects <FontAwesomeIcon icon={faChevronDown} /></Link>
                                    <div className="mega-menu-content">
                                        <div className="mega-menu-row">
                                            <div className="mega-menu-column border-0">
                                                <h3 className="mx-3 adi-text-primary">Project</h3>
                                                {menu &&
                                                    menu.map((item: any) =>
                                                    item.location === 'dp1' ? ( // Check if the location is 'ab1'
                                                        <ul className="m-0 p-0">
                                                            <li key={item.id}>
                                                            <Link to={`/page/${item.slug}`}>{item.title}</Link>
                                                            </li>
                                                        </ul>
                                                    ) : null // Skip rendering if the location is not 'ab1'
                                                )}
                                            </div>
                                            <div className="mega-menu-column border-0">
                                                <h3 className="mx-3 adi-text-primary">ENRICH Projects</h3>
                                                {menu &&
                                                    menu.map((item: any) =>
                                                    item.location === 'dp2' ? ( // Check if the location is 'ab1'
                                                        <ul className="m-0 p-0">
                                                            <li key={item.id}>
                                                            <Link to={`/page/${item.slug}`}>{item.title}</Link>
                                                            </li>
                                                        </ul>
                                                    ) : null // Skip rendering if the location is not 'ab1'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="menu-item mega-menu">
                                    <Link to="/governance" className={`${linkUrl === '/governance' ? 'active' : ''}`}>Archive <FontAwesomeIcon icon={faChevronDown} /></Link>
                                    <div className="mega-menu-content">
                                        <div className="mega-menu-row border-0">
                                            <div className="mega-menu-column border-0">
                                                {/* <h3>--</h3> */}
                                                {menu &&
                                                    menu.map((item: any) =>
                                                    item.location === 'arc1' ? ( // Check if the location is 'ab1'
                                                        <ul className="m-0 p-0">
                                                            <li key={item.id}>
                                                            <Link to={`/page/${item.slug}`}>{item.title}</Link>
                                                            </li>
                                                        </ul>
                                                    ) : null // Skip rendering if the location is not 'ab1'
                                                )}
                                                <ul className="m-0 p-0">
                                                    <li><Link to="/gallery">Gallery</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="menu-item"><Link to="/contact" onClick={() => changeLinkUrl('/contact')} className={`${linkUrl === '/contact' ? 'active' : '' }`}>Contact</Link></li>                                
                            </ul>                        
                        </div>
                        <div className="col-xl-3 col-lg-2 col-md-12 col-sm-12 social-links d-flex align-items-center justify-content-end">
                            <button type="button" className="fs-20 btn text-white py-0 d-block d-lg-none" onClick={() => setShowMenu(!showMenu)} ><FontAwesomeIcon icon={faBars} /></button>
                            <Link to="#0"><FontAwesomeIcon icon={faFacebook}/></Link>
                            <Link to="#0"><FontAwesomeIcon icon={faTwitter}/></Link>
                            <Link to="#0"><FontAwesomeIcon icon={faInstagram}/></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Menu;
